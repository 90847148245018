import { Navbar, Main, Footer, Header  } from "./components";
import SEO from "./components/SEO";

function App() {
  return (
    <>
    <SEO />
      <Navbar />
      <Header />
      <Main />
      <Footer />
    </>
  );
}

export default App;
