import React, { useEffect } from "react";
import profcessImage from "../img/process.png";
import Aos from "aos";
import "../styles/process.css";

const Process = () => {
  useEffect(() => {
    Aos.init({
      duration: 1500, // Set the animation duration in milliseconds
    });
  }, []);
  return (
    <>
    <div
        style={{  width: "100%", height: "30px" }}
        className="processScroll"
      ></div>
      <div className="container " style={{ marginTop: "6rem" }}>
        <h2 className="main-title about-h2" style={{ textAlign: "center" }}>
          Our Process
        </h2>
        <div className="process">
          <div data-aos="fade-right">
            <img className=" process_image" alt="process" src={profcessImage} />
          </div>
          <ul data-aos="fade-left">
            <li className=" process_content">
              <span>
                <b>Discovery:</b> We start by understanding your requirements
                and goals, and then we recommend the best solutions and
                technologies to use.
              </span>
            </li>
            <li className=" process_content">
              <span>
                <b>Design:</b> Once we have a good understanding of the project,
                we create a detailed plan and design that includes mockups and
                user flows.
              </span>
            </li>
            <li className=" process_content">
              <span>
                <b>Development:</b> We proceed to build the project using Python
                , Typescript, and/or other relevant technologies. We follow agile
                development practices, and we keep you informed of our progress 
                every step of the way.
              </span>
            </li>
            <li className=" process_content">
              <span>
                <b>Testing:</b> We test each feature, module, and functionality
                to ensure that the project meets all the requirements.
              </span>
            </li>
            <li className=" process_content">
              <span>
                <b>Deployment:</b> After thorough testing, we deploy the project
                to your server or the cloud, depending on your preferences.
              </span>
            </li>
            <li className="process_content">
              <span>
                <b>Support:</b> We provide ongoing support, maintenance, and
                updates to ensure that the project runs smoothly.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Process;
