import coverPhoto from "../img/cover.jpg"
import "../styles/header.css"
function Header() {
  return (
    <header>
      <img src={coverPhoto} className="cover_image" alt="cover_image"/>
      <h1>Krinay Innovations</h1>
      <p>Helping Ease of Business through Software Solutions</p>
      <div className="headerbg"></div>
    </header>
  );
}

export default Header;
