import React from "react";
import PropTypes from "prop-types";

const seoData = {
  title: "Krinay Innovations",
  description: "Empowering the Invisible Backbone of Innovation",
  url: "https://krinay.com/",
  keywords: [
    "Krinay",
    "Krinay Innovations",
    "Software development company",
    "Best rising software development company",
    "Custom software development company",
    "Outsourced software development company",
    "Offshore software development company",
    "MVP software development company",
    "ERP software development company",
    "Software development outsourcing company",
    "AI software development company",
    "Business software development company",
    "Finance software development company",
    "Healthcare software development company",
    "Medical software development company",
    "E-commerce software development company",
    "Realestate software development company",
    "Real estate software development company",
    "Enterprise software development company",
    "Fintech software development company",
    "Agile offshore software development company",
    "App development company",
    "App software development company",
    "Software development company in Sydney",
    "Software development company in India",
    "Software development company in USA",
    "Software development company in Canada",
    "Software development company in Australia",
    "Software development company in Germany",
    "Software development company in France",
    "Software development company in Spain",
    "Software development company in London",
    "Software development company in United Kingdom",
    "Software development services",
  ],
};

export default function SEO() {
  return (
    <head>
      <title>{seoData.title}</title>
      <meta name="title" content={seoData.title} />
      <meta name="author" content={seoData.author} />
      <meta name="description" content={seoData.description} />
      <meta name="keywords" content={seoData.keywords.join(", ")} />
      <link rel="canonical" href={seoData.url} />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seoData.url} />
      <meta property="og:title" content={seoData.title} />
      <meta property="og:description" content={seoData.description} />
      <meta property="og:site_name" content={seoData.title} />
    </head>
  );
}

SEO.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    author: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    url: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
