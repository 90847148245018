import React, {useEffect} from "react";
import Aos from "aos";
import aboutImage from "../img/about.jpg";
import "../styles/about.css"

function About() {
  useEffect(() => {
    Aos.init({
      duration: 1500, // Set the animation duration in milliseconds
    });
  }, []);

  return (
    <>
      <div
        style={{marginTop: "4rem", height: "30px"}}
        className="aboutScroll"
      ></div>

      <div className="container">
        <div className="about">
          <div data-aos="fade-right">
            <img className="about_image" alt="about" src={aboutImage}/>
          </div>
          <div data-aos="fade-left">
            <h2 className="main-title about-h2">About</h2>
            <p className="about_content">
              Welcome to Krinay Innovations, your trusted partner in innovative software solutions. We are a dynamic and
              forward-thinking software consultancy company that specializes in a wide range of services to cater to
              your unique business needs. With a team of highly skilled professionals, we are committed to delivering
              excellence in every project we undertake.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
