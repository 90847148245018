import React, {useEffect} from "react";
import Card from "./Card";
import APIImage from "../img/api.jpg";
import desktopImage from "../img/desktop.jpg";
import softwareImage from "../img/software.jpg";
import dataImage from "../img/data.jpg";
import automationImage from "../img/automation.jpg";
import cloudImage from "../img/cloud.jpg";
import "../styles/services.css";
import Aos from "aos";

function Services() {
  useEffect(() => {
    Aos.init({
      duration: 1500, // Set the animation duration in milliseconds
    });
  }, []);

  return (
    <>
      <div className="servicesScroll" style={{height: "30px"}}></div>
      <div className="container services">
        <h2 className="main-title text-center">Services</h2>
        <div className="card-cover">
          {/* Web application development */}
          <div className="card-row" data-aos="fade-up-right">
            <Card
              title="Web Applicaton Development"
              img={softwareImage}
              text1={
                <>
                  <b>AWS Expertise:</b> Our team includes certified AWS Solutions Architects who are experts in
                  leveraging the full potential of Amazon Web Services. Whether you require cloud-based hosting,
                  serverless computing, or data storage, we have the knowledge and experience to optimize your AWS
                  infrastructure.
                </>
              }
              text2={
                <>
                  <b>Typescript and NextJS for Backend Development:</b> We are dedicated to leveraging the power of 
                  Next.js for building dynamic and efficient backend systems. Next.js, renowned for its server-side 
                  rendering and static generation capabilities, enables us to develop high-performance applications 
                  tailored to meet your specific needs. Our expertise in Next.js, combined with its rich ecosystem 
                  and flexibility, ensures that we deliver scalable and fast applications that exceed your 
                  expectations.
                </>
              }
              text3={
                <>
                  <b>Diverse Database Solutions:</b> We understand that every project has unique data requirements.
                  That's why we offer a variety of databases, including PostgreSQL, MongoDB, ElasticSearch, Redis,
                  Pinecone and more, depending on the use case. This flexibility ensures that your data is stored and
                  managed efficiently.
                </>
              }
              text4={
                <>
                  <b>React for Frontend Development:</b> When it comes to crafting stunning and highly responsive user
                  interfaces, we turn to React. Our frontend developers are well-versed in React's capabilities,
                  enabling us to create engaging, user-friendly web applications.
                </>
              }
            />
          </div>
          {/* Cloud computing */}
          <div className="card-row" data-aos="fade-up-left">
            <Card
              title="Cloud Computing"
              img={cloudImage}
              text1={
                <>
                  <b>Cloud Hosting:</b> We provide reliable, scalable,
                  and secure cloud hosting solutions for applications.
                  Our hosting platform ensures that your applications run
                  smoothly, providing users with fast load times, high
                  availability, and low latency. Our hosting services are
                  fully managed, which means that we take care of all the
                  infrastructure and maintenance tasks so that your team can
                  focus on building your applications.
                </>
              }
              text2={
                <>
                  <b>Cloud Development:</b> Our team of expert
                  developers can help you build, deploy, and manage your
                  applications in the cloud. We use the latest cloud
                  technologies to ensure that your app is secure, scalable,
                  and highly available at all times. Our development services
                  include cloud migration, serverless architecture, and API
                  integration.
                </>
              }
              text3={
                <>
                  <b>Cloud Consulting:</b> Our consulting services can
                  help you optimize your existing cloud-based infrastructure. We provide expert analysis,
                  recommendations, and implementation services to help you get the most out of
                  your cloud computing resources. Our consulting services
                  include performance optimization, cost analysis, and
                  infrastructure planning.
                </>
              }
              text4={
                <>
                  <b>Cloud Support:</b> Our support team is available
                  around the clock to help you with your cloud
                  computing needs. We provide fast and efficient support
                  through email, phone, and chat channels. We are committed to
                  ensuring that your cloud applications run smoothly and that
                  your business is up and running at all times.
                </>
              }
            />
          </div>
          {/* Desktop application development */}
          <div className="card-row" data-aos="fade-up-right">
            <Card
              title="Desktop Application Development"
              img={desktopImage}
              text1={
                <>
                  <b>Cross-Platform Compliant using Electron JS:</b> We understand the importance of making your desktop
                  software accessible to a broad audience. To achieve this, we utilize Electron JS, which enables us to
                  create cross-platform applications compatible with Windows, macOS, and Linux. This approach ensures
                  that your software reaches a wider user base without compromising on performance or features.
                </>
              }
              text2={
                <>
                  <b>User-Centric Design:</b> Our approach to desktop software development prioritizes the user
                  experience. We create intuitive and user-friendly interfaces that make your software a pleasure to
                  use.
                </>
              }
              text3={
                <>
                  <b>High-Performance Solutions:</b> Performance is a top priority for us. We ensure that your desktop
                  software runs smoothly, regardless of the complexity of the tasks it needs to perform.
                </>
              }
              text4={
                <>
                  <b>Customization and Scalability:</b> We build desktop applications that are not only tailored to your
                  current needs but are also designed with scalability in mind, enabling them to grow as your business
                  does.
                </>
              }
            />
          </div>
          {/* Data processing and analytics  */}
          <div className="card-row" data-aos="fade-up-left">
            <Card
              title="Data Processing and Analysis"
              img={dataImage}
              text1={
                <>
                  {" "}
                  <b>Data Collection and Extraction:</b> We specialize in
                  extracting and collecting data from various sources such as
                  websites, databases, and social media platforms.
                </>
              }
              text2={
                <>
                  <b>Data Cleansing, Transformation, and Integration:</b> We
                  help businesses to clean and transform their raw data into
                  structured and organized forms that are easy to analyze. We
                  also integrate data from multiple sources to provide a
                  holistic view of your business.
                </>
              }
              text3={
                <>
                  <b>Data Analysis and Visualization:</b> We use
                  state-of-the-art data analysis tools and techniques to
                  provide insights into your business performance. We create
                  visually appealing dashboards and reports that help you make
                  data-driven decisions.
                </>
              }
              text4={
                <>
                  <b>Customized Solution:</b> We provide customized solutions
                  tailored to the specific needs of our clients. We work with
                  businesses of all sizes to provide comprehensive data
                  processing and analysis services.
                </>
              }
            />
          </div>
          {/* API Development 3 */}
          <div className="card-row" data-aos="fade-up-right">
            <Card
              title="API Development"
              img={APIImage}
              text1={
                <>
                  <b>API Design and Development:</b> Our team will work with
                  you to design and develop custom APIs that meet your
                  specific business needs, including RESTful APIs, GraphQL
                  APIs, and gRPC APIs.
                </>
              }
              text2={
                <>
                  <b>API Integration:</b> We can integrate your API with any
                  third-party application or service, making it easier to
                  automate workflows and streamline your business processes.
                </>
              }
              text3={
                <>
                  <b>API Documentation and Testing:</b> We provide thorough
                  documentation for all our APIs, making it easy for other
                  developers to build applications on top of your API. We also
                  conduct extensive testing to ensure your API is reliable and
                  performs at its best.
                </>
              }
            />
          </div>
          {/* Automation */}
          <div className="card-row" data-aos="fade-up-left">
            <Card
              title="Automation"
              img={automationImage}
              text1={
                <>
                  <b>Python Automation:</b> We use Python to automate a wide
                  range of tasks such as data processing, web scraping,
                  browser automation, email automation, and more. Our Python
                  Automation service is ideal for businesses, organizations,
                  and individuals who need to automate their repetitive and
                  time-consuming tasks.
                </>
              }
              text2={
                <>
                  <b>Provide Python Customization:</b> We provide customized
                  Python automation solutions tailored to your specific
                  requirements. Our team of experts will work closely with you
                  to understand your workflow and identify areas that can be
                  automated. We use modern Python libraries and frameworks
                  such as Selenium, BeautifulSoup, Pandas, and Flask to
                  provide fast and reliable solutions.
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
