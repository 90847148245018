import {About, Services, Contact, Choose, Process} from "./index"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function Main() {
  useEffect(() => {
    AOS.init({
      duration: 1500, // Set the animation duration in milliseconds
    });
  }, []);
  return (
    <>
      <main>
        <About />
          <Services />
        <Process />
        <Choose /> 
        <div data-aos="fade-up">
          <Contact />
        </div>
      </main>
    </>
  );
}
export default Main;
