import { animateScroll as scroll } from "react-scroll";
import companyLogo from "../img/thumb_krinay-logo.png";
import "../styles/footer.css";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="side1">
            <div className="row">
              <div className="col-md-3 first_content">
                <img className="company_logo" src={companyLogo} alt="logo" />
                <h1 className="logo">Krinay Innovations</h1>
              </div>
              <div className="col-md-3">
                <p className="footer-title">Details</p>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://goo.gl/maps/KRpPuiL5Re5RQjZC7"
                    >
                      <i className="fas fa-map-marker-alt"></i> Meydan Grandstand, 6th Floor, Meydan Road, Nad Al Sheba, Dubai, UAE
                    </a>
                  </li>
                  <li>
                    <p className="tax">License Number: 2419210.01</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="side2">
            <div className="row">
              <div className="col-md-3">
                <p className="footer-title">Contact</p>
                <ul>
                  <li>
                    <a href="mailto:info@krinay.com" rel="noreferrer">
                      <i className="fas fa-envelope"></i> info@krinay.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:+919033301373" rel="noreferrer">
                      <i className="fas fa-phone"></i> +971 55 332 5076
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                {/* add your social links here instead of '#' */}
                <p className="footer-title">Social Media</p>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/company/96447324/"
                    >
                      <i
                        className="fab fa-linkedin"
                        style={{ marginRight: "5px" }}
                      ></i>Linkedin
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} className="gotop">
        <i className="fas fa-level-up-alt"></i>
      </button>
    </footer>
  );
}

export default Footer;
