import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "../styles/contact.css";


function Contact() {
  const form = useRef();
  const [errorMessage, setErrorMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    if (form.current.checkValidity()) {
      const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
      const EMAILJS_SEVICE_KEY = process.env.REACT_APP_EMAILJS_SEVICE_KEY;
      const EMAILJS_TEMPLATE_KEY = process.env.REACT_APP_EMAILJS_TEMPLATE_KEY;
      emailjs
      // Enter your key here
        .sendForm(
          EMAILJS_SEVICE_KEY, //remove the text and enter service key
          EMAILJS_TEMPLATE_KEY,//remove the text and enter templet key
          form.current,
          EMAILJS_PUBLIC_KEY//remove the text and enter public key
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      form.current.reset();
      setErrorMessage("");
    } else {
      setErrorMessage("Please fill out all the required fields.");
    }
  };

  return (
    <>
      <form
        ref={form}
        onSubmit={sendEmail}
        className="container contact"
        style={{ marginTop: "6rem" }}
      >
        <h2 className="main-title text-center">Contact</h2>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="input-container">
                <input
                  name="from_name"
                  placeholder="Name"
                  className="contact-input"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="input-container">
              <input
                name="user_email"
                placeholder="Email"
                className="contact-input"
                type="email"
                required
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="input-container">
              <input
                name="subject"
                placeholder="Subject"
                className="contact-input"
                required
              />
            </div>
          </div>
        </div>
        <br />
        <div className="col-md-12">
          <textarea
            name="message"
            placeholder="Message"
            className="contact-textarea"
            required
          />
        </div>

        <br />
        <div className="row">
          <div className="col-md-12">
            <input className="form-btn" type="submit" value="Send Message" />
          </div>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </form>
    </>
  );
}

export default Contact;
