import React, { useEffect } from "react";
import chooseImage from "../img/choose.png";
import Aos from "aos";
import "../styles/choose.css";

const Choose = () => {
  useEffect(() => {
    Aos.init({
      duration: 1500, // Set the animation duration in milliseconds
    });
  }, []);

  return (
    <>
      <div
        style={{  height: "30px" }}
        className="chooseScroll"
      ></div>
    <div className="container" style={{ marginTop: "6rem" }}>
      <h2 className="main-title about-h2" style={{ textAlign: "center" }}>
        Why Choose Us?
      </h2>
      <div className="choose">
        <div data-aos="fade-right">
          <img className="choose_image" alt="choose" src={chooseImage} />
        </div>
        <ul data-aos="fade-left">
          <li className="choose_content">
            <span>
              <b>Experienced Team:</b> We have a team of experienced developers
              and consultants who understand Python and Typescript inside out.
            </span>
          </li>
          <li className="choose_content">
            <span>
              <b>Custom Solutions:</b> We offer customized solutions that fit
              your business needs, ensuring that you get the best return on your
              investment.
            </span>
          </li>
          <li className="choose_content">
            <span>
              <b>Agile Development:</b> We follow agile development practices,
              which means that we deliver projects on time and within budget.
            </span>
          </li>
          <li className="choose_content">
            <span>
              <b>Communication:</b> We keep you informed of our progress every
              step of the way, and we listen to your feedback to ensure that the
              project meets your expectations.
            </span>
          </li>
          <li className="choose_content">
            <span>
              <b>Compliance Expertise:</b> We are committed to ensuring that your software solutions meet the
              highest standards of security and data protection. Our experience in compliance with regulations such
              as GDPR, PCI-DSS, HIPAA, and others ensures that your applications are not only feature-rich but also
              secure and privacy-compliant.
            </span>
          </li>
        </ul>
      </div>
    </div>
    </>
  );
};

export default Choose;
